import React, { useEffect } from "react";
import "./ToolsConsultGptFilepane.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CONSULT_FILE_PANE,
  FILE_UPLOAD_DASHBOARD,
} from "../../../../store/consultgptDataSlice/common";
import {
  EnableFilePreview,
  resetChatReferenceData,
  setActiveFilePane,
  setActiveUploadDashboard,
  toggleShowConsultFilePane,
} from "../../../../store/consultgptDataSlice/consultgptDataSlice";
import { IoMdArrowBack } from "react-icons/io";
import ToolsConsultGptPdfPreview from "./ToolsConsultGptPdfPreview/ToolsConsultGptPdfPreview";
import ToolsConsultGptFileSection from "./ToolsConsultGptFileSection/ToolsConsultGptFileSection";
// import toast from "react-hot-toast";

const ToolsConsultGptFilepane = () => {
  const { active_file_tab, consult_file_pane_sidebar, file_preview } =
    useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();

  const handleToggleFilePane = (pane) => {
    // if (
    //   pane === CONSULT_FILE_PANE.PREVIEW &&
    //   !file_preview.is_file_preview_enabled
    // ) {
    //   toast.error("Select a file to see preview", {
    //     id: "preview-disable",
    //   });
    // } else {
    // }
    dispatch(setActiveFilePane(pane));
  };

  const onConsultFileBackBtn = () => {
    dispatch(toggleShowConsultFilePane(false));
    dispatch(setActiveUploadDashboard(FILE_UPLOAD_DASHBOARD.UPLOAD));
    dispatch(resetChatReferenceData());
  };

  useEffect(() => {
    if (file_preview.is_file_preview_enabled) {
      dispatch(setActiveFilePane(CONSULT_FILE_PANE.PREVIEW));
    } else {
      dispatch(setActiveFilePane(CONSULT_FILE_PANE.UPLOAD));
    }
  }, [file_preview.is_file_preview_enabled]);

  useEffect(() => {
    if (active_file_tab === CONSULT_FILE_PANE.UPLOAD) {
      dispatch(EnableFilePreview(false));
      dispatch(resetChatReferenceData());
    }
  }, [active_file_tab]);

  return (
    <div
      className={`tools_consult_file_pane ${
        consult_file_pane_sidebar && "tools_consult_file_pane_show"
      }`}
    >
      <div className="tools_consult_file_pane_tab_btn_wrapper">
        <button
          className="tools_consult_back_icon_btn tools_consult_back_icon_btn_border"
          onClick={onConsultFileBackBtn}
        >
          <IoMdArrowBack />
        </button>
        <button
          className={`tools_consult_file_pane_tab_btn ${
            active_file_tab === CONSULT_FILE_PANE.PREVIEW
              ? "tools_consult_active_tab"
              : "tools_consult_unactive_tab"
          }`}
          onClick={() => handleToggleFilePane(CONSULT_FILE_PANE.PREVIEW)}
          // disabled={!file_preview.is_file_preview_enabled}
        >
          Preview
        </button>
        <button
          className={`tools_consult_file_pane_tab_btn ${
            active_file_tab === CONSULT_FILE_PANE.UPLOAD
              ? "tools_consult_active_tab"
              : "tools_consult_unactive_tab"
          }`}
          onClick={() => handleToggleFilePane(CONSULT_FILE_PANE.UPLOAD)}
        >
          Upload File
        </button>
      </div>
      {active_file_tab === CONSULT_FILE_PANE.UPLOAD && (
        <ToolsConsultGptFileSection />
      )}
      {active_file_tab === CONSULT_FILE_PANE.PREVIEW && (
        <ToolsConsultGptPdfPreview />
      )}
    </div>
  );
};

export default ToolsConsultGptFilepane;
